import { Component, Input, OnInit } from '@angular/core';
import { IssueType } from '@shared/classes/model/backend/issue';

import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModuleKeywords } from '@shared/classes';
import { ViewModeService } from '@shared/services/view-mode.service';
import { BaseForm } from 'app/shared/classes/view/BaseForm';
import { IssueTypeDataService } from '../../services/data/issue-type-data.service';

@Component({
  selector: 'app-issue-types-item-form',
  templateUrl: './issue-types-item-form.component.html',
  styleUrls: ['./issue-types-item-form.component.scss'],
})
export class IssueTypesItemFormComponent extends BaseForm<IssueType> implements OnInit {
  @Input()
  itemId: string;

  @Input()
  searchT: string = null;

  @Input()
  editMode: Boolean = false;
  constructor(
    private requestService: IssueTypeDataService,

    public viewModeService: ViewModeService
  ) {
    super(viewModeService, ModuleKeywords.IssueType);
  }
  getData() {
    return this.viewModeService.viewMode == 'create'
      ? this.getDataKeyValueFormat()
      : this.getChangedFormValues().updateItems;
  }

  setData(data: any) {
    this.formGroup.patchValue({ ...data });
    this.data = data;
  }
  ngOnInit(): void {}

  initFormStructure(): void {
    this.formGroup = new FormGroup({
      name: new FormControl(null, Validators.required),
      description: new FormControl(null),
    });
  }
}
