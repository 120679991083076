import { Component } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { IssueType } from '@shared/classes/model/backend/issue';
import { ToastService } from '@shared/services/toast.service';
import { ViewModeService } from '@shared/services/view-mode.service';
import {
  ItemBasePage,
  ModuleKeywords,
  ModuleRoutePrefix,
  RequestHandlerOptions,
  TargetTypeEnum,
  toKebabCase,
} from 'app/shared/classes';
import { combineLatest, takeUntil } from 'rxjs';
import { IssueTypeDataService } from '../../services/data/issue-type-data.service';

@Component({
  selector: 'app-issue-types-item',
  templateUrl: './issue-types-item.component.html',
  styleUrls: ['./issue-types-item.component.scss'],
})
export class IssueTypesItemComponent extends ItemBasePage<IssueType> {
  realTimeFormData: any = {}; //Copy of form data that changes realtime when form changes

  apiOptions: RequestHandlerOptions = {
    showLoading: true,
    showMsg: false,
  };

  constructor(
    private requestService: IssueTypeDataService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private toastService: ToastService,
    public viewModeService: ViewModeService
  ) {
    super(
      {
        moduleKeyword: ModuleKeywords.IssueType,
        routePrefix: ModuleRoutePrefix.Issue,
      },
      router,
      requestService,
      toastService
    );

    combineLatest([this.activatedRoute.paramMap])
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.itemId = data[0].get('id');
        this.editMode = !!this.itemId;
        this.SetPageValues({
          breadCrumb: {
            items: [
              {
                label: 'Issue Management',
                routerLink: [`${this.routePrefix ? this.routePrefix : toKebabCase(this.moduleKeyword)}`],
              },
              {
                label: 'Issue Types',
                routerLink: [`/${this.routePrefix}${toKebabCase(this.moduleKeyword)}/list`],
              },
              {
                label: this.itemId ? this.itemId : 'Add Issue Type',
                routerLink: [],
              },
            ],
          },
          itemId: this.itemId,
          fromType: TargetTypeEnum.IssueType,
        });
      });
  }
}
